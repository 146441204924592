import React from 'react';
import Navigation from './Navigation';

const Posts = () => {
    return (
        <div class='bg-gray-300 min-h-screen pb-10'>
            <Navigation/>
            <div class='relative top-5 flex justify-center font-serif'>
                <div class='bg-white min-w-screen md:w-2/3 min-h-screen rounded-lg shadow-lg space-y-10 py-5 px-20'>
                    <p class='text-left text-3xl '> Posts</p>
                    <div>
                        <a href='#posts/vae' class='text-left text-3xl text-blue-500 hover:underline hover:text-black'> An Introduction to Variational Autoencoders</a>
                    </div>
                    
                </div>
            </div>
        </div>
    );
};

export default Posts;