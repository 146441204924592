import React from 'react';
import Navigation from './Navigation';

const Projects = () => {
    return (
            <div class='bg-gray-300 min-h-screen pb-10'>
                <Navigation/>
                <div class='relative top-5 flex justify-center'>
                    <div class='bg-white min-w-screen md:w-2/3 min-h-screen rounded-lg shadow-lg space-y-4 p-5'>
                        <p class='text-left text-xl font-semibold'> Projects</p>
                        <p class='text-left text-lg font-semibold'> Code Comprehension Tutor (JavaScript, React, Docker, LLM, Express.js)</p>
                        <p class='text-left'>
                            The idea for this project was to build an app that can measure a users' ability to read and interpret a sample block of code simply by the 
                            users' natural-language description of the code's functionality, and offer feedback on the accuracy of the provided description (hence, "comprehension tutor").
                            The app combines a pre-written text-prompt with the provided code description and passes it to a large language model, which generates code that 
                            is faithful to the users' description––the resulting code is run against a test suite specific to the described code sample and taken as a measure of the description's accuracy.
                        </p>
                        <p class='text-left'>
                            This project demonstrates the ability to develop a dynamic web application, implement API requests to an open-sourced LLM, and work cooperatively in a small agile-based development team.
                        </p>

                        <p class='text-left text-lg font-semibold'> Reinforcement Learning Tictactoe (Python, TensorFlow, Matplotlib)</p>
                        <p class='text-left'>
                            In this project I designed an object-oriented platform for abstract models to play against each other in a simulated tictactoe game, 
                            and used it to train a custom deep neural network using reinforcement learning algorithms implemented from scratch. This project demonstrates 
                            proficiency with object oriented programming concepts and SOLID principles, a high-level understanding of deep learning algorithms and frameworks, and the 
                            ability to quickly self-learn complex topics and implement them into a project. 
                        </p>

                        <p class='text-left text-lg font-semibold'> Organic Chemistry Path Solver (Java)</p>
                        <p class='text-left'>
                            Inspired by organic chemistry "synthesis" exam problems, this program is designed to allow users to create, save, and modify graphical data stored in a JSON file format, 
                            to be used for path-searching problems for arbitrary contexts. Demonstrates understanding of SOLID principles and object-oriented design patterns, ability to use unit testing
                            tools to assess code coverage, and design robust user-friendly software.
                        </p>
                            {/* <img class='size-1/2' src={vae_animation} alt={''} /> */}
                    </div>
                </div>
            </div>
    );
};

export default Projects;