// src/Header.js
import React from 'react';
import profile_pic from './profile_pic.png';
import parkour1 from './parkour1.PNG';
import parkour3 from './parkour3.jpg';
import smash1 from './smashult_club.JPG';

const Home = () => {

  const parkour_images = [parkour1, parkour3];  
  const smash_images = [smash1];

  return (
    <div class="relative top-5">
      <div class="flex justify-center">
        <div className="shadow-lg bg-gray-50 min-w-screen md:w-2/3 py-5 px-5 rounded-md">
          <div className="space-y-4">
            <p className="text-4xl font-semibold text-center">Hi, I'm Bryn Lee Meyer.</p>
            <div class="flex justify-center">
              <img class="rounded-full h-32 w-32 object-cover" src={profile_pic} alt="profile picture"/>  
            </div>
            <p className='text-left'>
            I'm a 4th year student at the University of British Columbia, double majoring in Computer Science and Biochemistry. 
            Deep Learning and AI is both my passion and my hobby. 
            This is my personal website where I blog, share toy projects I've been working on, and explore interesting concepts in machine learning research and attempt to understand them. 
            </p>
            <p className='text-left'>
              Although my main interest is AI, I am an overall very curious person; I love to learn and research topics from all kinds of science fields. Aside from the obviously related
              fields of statistics, math and computer science, I love to read about physics and biology topics in my free time. Every so often while digging into any topic in a science field,
              you'll find an unexpected crossover into a seemingly unrelated field––and to me that is inescapably captivating.
            </p>

            <div class="shadow-inner shadow-xl bg-white w-6/7 py-5 px-5 rounded-md max-h-50">
              <p class="text-left text-xl font-semibold">Hobbies</p>
              <p class="text-left text-lg font-semibold">Parkour</p>
              <p class="text-left">I started training parkour in highschool and also worked part-time as an assistant parkour instructor in the summer. I still like to train from time to time with my friends.</p>
              <div class='flex justify-center'>
                <div class='overflow-x-scroll whitespace-nowrap w-48'>
                  {parkour_images.map((src,index) => {
                    return <img 
                      src={src} 
                      alt={`Image ${index+1}`} 
                      class='inline-block h-64 w-48 mr-4 object-cover rounded-lg'
                    />
                  })}
                </div>
                {/* can maybe add buttons for scrolling images later on instead*/}
              </div>

              <p class="text-left text-lg font-semibold">Competitive Smash Ultimate</p>
              <p class="text-left">I enjoy competing in tournaments for Super Smash Bros Ultimate, and help with setting up our club's weekly tournaments hosted at UBC.</p>
              <div class='flex justify-center'>
              <div class='overflow-x-scroll whitespace-nowrap w-48'>
                {smash_images.map((src,index) => {
                  return <img 
                    src={src} 
                    alt={`Image ${index+1}`} 
                    class='inline-block h-64 w-48 mr-4 object-cover rounded-lg'
                  />
                })}
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
