import React from 'react'
import Navigation from './Navigation';

const Contact = () => {
    return (
      <div class='bg-gray-300 min-h-screen'>
        <Navigation/>
        <div class='relative top-5 flex justify-center'>
            <div class='bg-white w-2/3 md:w-1/3 top-20 rounded-lg shadow-xl px-5 py-5 space-y-4'>
                <p class='text-left text-xl font-semibold'> Contact</p>
                <p class='text-left text-md'> Email: brynleemeyer@gmail.com</p> 

                <div class='flex justify-left space-x-4'>
                    <p class='text-left text-md'> LinkedIn:</p>
                    <a class='text-blue-500 underline' href='https://linkedin.com/in/brynlm' target='_blank'>brynlm</a>
                </div>
                
                <div class='flex justify-left space-x-4'>
                    <p class='text-left text-md'> GitHub:</p>
                    <a class='text-blue-500 underline' href='https://github.com/brynlm' target='_blank'>brynlm</a>
                </div>
            </div>
        </div>
      </div>
    );
};

export default Contact;