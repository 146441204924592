// src/App.js
import React from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Home';
import Navigation from './Navigation';
import Projects from './Projects';
import Contact from './Contact';
import Posts from './Posts';
import Vaepost from './vae';



function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<div className="bg-gray-300 min-h-screen pb-10">
                                  <Navigation />
                                  <Home />
                                </div>} 
                                />
        <Route path="/projects" element={<Projects />}/>
        <Route path="/contact" element={<Contact />}/>
        <Route path="/posts" element={<Posts />}/>
        <Route path="/posts/vae" element={<Vaepost/>}/>
      </Routes>
    </Router>

  );
}

export default App;

