// src/Navigation.js
import React from 'react';
// import './index.css';

const Navigation = () => {
  return (
    <nav className="shadow-lg bg-white p-4"> {/* blue background w/ darkness set to 800, 'p-4' padding class */}
        <p class='text-center font-semibold'>Bryn Lee Meyer</p>

        <div className="flex justify-center space-x-4">
            <a href="#" className="text-black transition duration-300 hover:text-blue-300 hover:scale-110">Home</a>
            <a href="#projects" className="text-black transition duration-300 hover:text-blue-300 hover:scale-110">Projects</a>
            <a href="#contact" className="text-black transition duration-300 hover:text-blue-300 hover:scale-110">Contact</a>
            <a href="#posts" className="text-black transition duration-300 hover:text-blue-300 hover:scale-110">Posts</a>
        </div>
    </nav>
  );
};

export default Navigation;
